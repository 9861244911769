body {
  width: 100vw;
  height: 100%;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  scroll-behavior: smooth;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
.header {
  position: fixed;
  width: 100vw;
  border-bottom: 1px solid rgb(236, 236, 236);
  background-color: white;
  z-index: 9999;
}
.header-container {
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 8px;
  display: flex;
}
.header-title img {
  width: 200px;
  margin-top: 24px;
}
.header-title:hover {
  cursor: pointer;
  opacity: 0.9;
}

.title-list-item:hover .title-list-item-dropdown {
  display: block;
}

.content {
  padding-top: 64px;
}

.dbl-block-title-smaller {
}

.full-block {
  margin-top: 64px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 800px;
}

.full-block-description {
  margin-top: 16px;
  font-size: 18px;
}
.full-image {
  width: 800px;
  border-radius: 16px;
  object-fit: cover;
}

hr {
  border: 0;
  border-top: 1px dotted #ccc;
}

.feature-block-wrapper {
  display: flex;
  margin-top: 64px;
  margin-right: auto;
  margin-left: auto;
}

.partner-block {
  /*background-color: #22485a;*/
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#22485a+0,3a6b6d+51,5a968a+100 */
  background: #22485a; /* Old browsers */
  background: -moz-linear-gradient(left,  #22485a 0%, #3a6b6d 51%, #5a968a 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left,  #22485a 0%,#3a6b6d 51%,#5a968a 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right,  #22485a 0%,#3a6b6d 51%,#5a968a 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#22485a', endColorstr='#5a968a',GradientType=1 ); /* IE6-9 */

  color: white;
  height: 540px;
}
.parner-block-inner {
  text-align: center;
  width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 32px;
}

.partner-block-title {
  font-size: 40px;
  font-weight: 500;
}
.partner-block-description {
  margin-top: 16px;
  font-size: 18px;
}

.partner-item {
  display: inline-flex;
  background-color: white;
  color: black;
  height: 340px;
  width: 928px;
  text-align: left;
  margin-top: 32px;
  border-radius: 8px;
  -webkit-box-shadow: 10px 10px 26px -10px rgba(0,0,0,0.45);
  -moz-box-shadow: 10px 10px 26px -10px rgba(0,0,0,0.45);
  box-shadow: 10px 10px 26px -10px rgba(0,0,0,0.45);
}
.partner-logo {
  width: 400px;
  display: flex;
  align-items: center;
}
.partner-logo img {
  height: 64px;
  margin-left: 64px;
  margin-right: 64px;
}
.partner-info {
  width: 400px;
  display: flex;
  align-items: center;
}
.partner-info-title {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 16px;
}
.partner-info-description {
  width: 400px;
  font-size: 18px;
  margin-bottom: 16px;
}
.writer-name {
  font-weight: 500;
}
.partner-item-button {
  width: 64px;
  border-radius: 8px;
  display: flex;
  align-items: center;

}
.partner-item-button .glyph {
  text-align: center;
  min-width: 64px;
  font-size: 24px;
  color: rgb(221, 221, 221);
}
.partner-item-button:hover {
  background-color: rgb(245, 245, 245);
  cursor: pointer;
}
.margin-top-md {
  margin-top: 64px;
}

.content-quote {
  font-size: 24px;
  color: #888;
}

/* New styles */

.content-grid {
  display: block;
  position: relative;
}

.app-content {
  margin-top: 92px;
  overflow-x: auto;
  /*padding-bottom: 72px;*/
}

.maranics-logo-sign {
  font-family: 'MaranicsFont', 'Roboto', sans-serif;
  color: #224859;
  font-size: 32px;
  font-weight: bold;
}